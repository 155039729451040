<template>
    <div>
        <div class="h-handle-bg">
            <div class="h-handle-button">
                <p class="h-return">
                    <el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
                </p>
                <!-- <div class="h-b">
                    <el-button type="primary" size="small" @click="addProject"
                        v-right-code="'Projectinfo:Getempty'">新增</el-button>
                </div> -->
                <div class="h-b"><el-button type="primary" size="small" @click="editApi"
						v-if="!config.isAdd && config.isCanEdit"  v-right-code="'Projectinfo:Edit'">编辑</el-button> </div>
                <div class="h-b">
                    <el-button type="primary" size="small" v-if="config.isAdd" @click="saveProject"  
                        v-right-code="'Projectinfo:Edit'">保存</el-button>
                </div>
            </div>
        </div>

        <el-tabs type="border-card" v-model="activeName" @tab-click="tab1Click">
            <el-tab-pane name="tabbasic" label="基本信息">
                <div class="form-list">
                    <el-form :model="dataSource" ref="_projectForm" :rules="projectCheckRule">
                        <el-form-item>
                            <el-col :span="3" class="form-title">项目编码：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="Code">
                                    <el-input type="text" :disabled="!config.isAdd" v-model="dataSource.Code"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>项目名称：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="Name">
                                    <el-input type="text" :disabled="!config.isAdd" v-model="dataSource.Name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <!-- <el-col :span="3" class="form-title">上级项目：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ParentProjectName">
                                    <el-select v-model="dataSource.ParentProjectName"  :disabled="!config.isAdd" placeholder="请选择"
                                        :popper-append-to-body="false" ref="elselecttree" clearable @clear="clearaSelTree">
                                        <el-option :value="dataSource.ParentProjectId" :label="dataSource.ParentProjectName"
                                            class="setstyle" :disabled="!config.isAdd">
                                            <el-tree :data="projectlist" :props="defaultprops"
                                                ref="treeshow-checkbox check-strictly" :expand-on-click-node="false"
                                                node-key="value" check-on-click-node @check-change="handleNodeClick">
                                            </el-tree>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col> -->
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>项目类型：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="Type">
                                    <DictionarySelect v-model="dataSource.Type" :disabled="!config.isAdd"
                                        dicTypeCode="ProjectTypeCode" @change="typeChange"></DictionarySelect>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>签约客户：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="SignCustomerName">
                                    <el-input v-model="dataSource.SignCustomerName" readonly suffix-icon="el-icon-search"
                                    :disabled="!config.isAdd" placeholder="请选择客户"
                                        @click.native="onCustomerClick()"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title"><span style="color:red;">*</span>我方签订单位：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="SigningUnitName">
                                    <el-input v-model="dataSource.SigningUnitName" readonly suffix-icon="el-icon-search"
                                    :disabled="!config.isAdd" placeholder="我方签订单位"
                                        @click.native="onSigningUnitClick"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">项目开始日期：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="StartDate">
                                    <el-date-picker v-model="dataSource.StartDate" :disabled="!config.isAdd"
                                        value-format="yyyy-MM-dd HH:mm:ss" type="date">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">项目结束日期：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="EndDate">
                                    <el-date-picker v-model="dataSource.EndDate" :disabled="!config.isAdd"
                                        value-format="yyyy-MM-dd HH:mm:ss" type="date">
                                    </el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">货主：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="CustomerName">
                                    <el-input v-model="dataSource.CustomerName" readonly suffix-icon="el-icon-search"
                                    :disabled="!config.isAdd" placeholder="货主"
                                        @click.native="onCustomerInfoClick"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">状态：</el-col>
                            <el-col :span="7">
                                <el-radio-group :disabled="!config.isAdd" v-model="dataSource.Status">
                                    <el-radio :label="100">启用</el-radio>
                                    <el-radio :label="200">停用</el-radio>
                                </el-radio-group>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">项目经理：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ProjectManager">
                                    <el-input type="text" :disabled="!config.isAdd"
                                        v-model="dataSource.ProjectManager"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="3" class="form-title">项目经理联系电话：</el-col>
                            <el-col :span="7">
                                <el-form-item prop="ProjectManagerPhone">
                                    <el-input type="text" :disabled="!config.isAdd"
                                        v-model="dataSource.ProjectManagerPhone"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                        <el-form-item>
                            <el-col :span="3" class="form-title">备注：</el-col>
                            <el-col :span="17">
                                <el-form-item prop="Remark">
                                    <el-input type="textarea" :disabled="!config.isAdd" v-model="dataSource.Remark"
                                        :maxlength="500"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>
        <el-tabs type="border-card" v-model="activeName2" @tab-click="tab2Click">
            <el-tab-pane name="tabcontract" label="合同信息">
                <ContractList ref="contractlist" :projectData="dataSource" :isDisabled="!config.isAdd">
                </ContractList>
            </el-tab-pane>
        </el-tabs>
        <DialogEx :options="orgOptions" title="我方签订单位" style="height:100%;" @onOk="onOrgOk">
            <Organization ref="organization" :config="dialogConfig" @onSelectedRow="onOrgSelectedRow">
            </Organization>
        </DialogEx>
        <DialogEx :options="customerOptions" title="客户选择" style="height:100%;" @onOk="onCustomerOk">
            <CustomerRelation ref="customerrelation" :config="dialogConfig" @onSelectedRow="onCustomerSelectedRow">
            </CustomerRelation>
        </DialogEx>
        <DialogEx :options="customerInfoOptions" title="货主选择" style="height:100%;" @onOk="onCustomerInfoOk">
            <CustomerInfo ref="customerrelation" :config="dialogConfig" @onSelectedRow="onCustomerInfoSelectedRow">
            </CustomerInfo>
        </DialogEx>
    </div>
</template>

<script>
export default {
    data() {
        var _this = this;
        return {
            dataSource: {

				},
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            activeName: 'tabbasic',
            activeName2: 'tabcontract',
            projectCheckRule: {
                Name: [{
                    required: true,
                    message: '请输入项目名称',
                    trigger: 'blur'
                }],
                Type: [{
                    required: true,
                    message: '请选择项目类型',
                    trigger: 'blur'
                }],
                // Code: [{ required: true, message: '请输入项目编码', trigger: 'blur' }],
                SignCustomerName: [{ required: true, message: '请输入签约客户', trigger: 'blur' }],
                SigningUnitName: [{ required: true, message: '请选择我方签订单位', trigger: 'blur' }],
            },
            dialogConfig: {
                isDetailDisplay: false,
                isSelectionDisplay: false,
            },
            orgOptions: {
                visible: false,
                size: 'large'
            },
            orgSelecteData: {},
            customerOptions: {
                visible: false,
                size: 'large'
            },
            customerSelecteData: {},
            projectlist: [],
            selectTree: "",
            defaultprops: {
                children: 'children',
                label: 'label'
            },
            customerInfoOptions: {
                visible: false,
                size: 'large'
            },
            customerInfoSelecteData: {},
        }
    },
    props: {
        config: {
            isDetailDisplay: false,
            dataSourceKey: null,
        },
    },
    computed: {

    },
    watch: {
        'config.dataSourceKey': {
				handler(curVal, oldVal) {
					if (curVal && curVal > 0) {
						this.getDetail(curVal); //初始化
					}else{
						if(curVal==null){
							this.dataSource={};
						}
						
					}
				},
				deep: true
			},
    },

    mounted() {
        //this.Event.$on("clearEditProjectForm", () => this.resetForm);        
    },

    methods: {
        addProject() {
            this.Event.$emit("onAddProject");          
        },
        add() {
            var _this =this;
            this.getProjectlist();
            this.$ajax.send("omsapi/projectinfo/getempty", "get", {}, (data) => {
                _this.dataSource = data.Result;
                console.log(_this.dataSource)
            });
        },
        tab1Click: function (tab, event) { },
        tab2Click: function (tab, event) {
            switch(tab.name){
                case "tabcontract":
                    break;
            }
         },
         getDetail(apiId) {
				var _this = this;
                this.getProjectlist();
				this.$ajax.send("omsapi/projectinfo/getbyid", "get", {
					id: apiId
				}, (data) => {
					_this.dataSource = data.Result;
                    _this.$refs.contractlist.getContractList(apiId);
				});
			},
        resetForm(id) {
            //!this.isPassValidate && this.$refs['_projectForm'].resetFields(); //清空表单
            this.getProjectlist();
            this.$refs.contractlist.getContractList(id);
        },
        saveProject() {
            var _this = this;
            _this.$refs["_projectForm"].validate((valid) => {
                _this.isPassValidate = valid;
                if (valid) {
                    var routeName = _this.dataSource.Id ===null ? "create" : "edit";
                    _this.$ajax.send("omsapi/projectinfo/"+routeName, "post", _this.dataSource, (data) => {
                        if (!_this.Utils.isGreaterThanZero(_this.dataSource.Id)) {
                            _this.dataSource.Id = data.Result
                        }
                        _this.$parent.syncDataSource();
                        _this.Event.$emit("reloadProjectList", data.Result);
                        _this.Utils.messageBox("保存成功.", "success");
                    });
                } else {
                    return false;
                }
            });
        },
        getProjectlist() {
            let _this = this;
            _this.projectlist = [];
          
            var fist = { label: '所有', value: 0, children: [] };
            _this.projectlist.push(fist);
            _this.$ajax.send("omsapi/projectinfo/getprojectlist", "get", {}, (data) => {
                if (data && data.Result.length > 0) {
                    _this.projectlist = [];
                    let allProjects = data.Result.map(item => {
                        return { label: item.Name, value: item.Id };
                    });
                    fist.children = allProjects;
                    _this.projectlist.push(fist);
                }
            });
        },
        // 切换选项
        handleNodeClick(node) {
            this.dataSource.ParentProjectId=node.value;
            this.dataSource.ParentProjectName=node.label;
            this.$refs.elselecttree.blur();
        },
        clearaSelTree(){
            this.dataSource.ParentProjectId='';
            this.dataSource.ParentProjectName='';
        },
        typeChange(val) {
            this.dataSource.Type = val;
        },
        onCustomerClick(index) {
            this.customerSelecteData = {};
            this.crRowIndex = index;
            this.customerOptions.visible = true;
        },
        onCustomerSelectedRow(val) {
            this.customerSelecteData = val;
        },
        onCustomerOk() {
            this.dataSource.SignCustomerId = this.customerSelecteData.Id;
            this.dataSource.SignCustomerName = this.customerSelecteData.Name;
            this.orgOptions.visible = false;
        },
        onSigningUnitClick() {
            this.orgSelecteData = {};
            this.orgOptions.visible = true;
        },
        onOrgSelectedRow(val) {
            this.orgSelecteData = val;
        },
        onOrgOk() {
            this.dataSource.SigningUnitId = this.orgSelecteData.Id;
            this.dataSource.SigningUnitName = this.orgSelecteData.Name;
            this.orgOptions.visible = false;
        },
        onCustomerInfoClick(){
            this.customerInfoSelecteData={};
            this.customerInfoOptions.visible = true;
        },
        onCustomerInfoOk(){
            this.dataSource.CustomerName = this.customerInfoSelecteData.CustomerName;
            this.dataSource.CustomerCode = this.customerInfoSelecteData.CustomerCode;
            this.dataSource.CustomerId = this.customerInfoSelecteData.CustomerId;
            this.customerInfoOptions.visible = false;
        },
        onCustomerInfoSelectedRow(row){
           this.customerInfoSelecteData=row;
        },
        editApi() {
				this.config.isAdd = true;
			},
    },
    components: {
        "Organization": resolve => {
            require(['@/components/business/selector/organizationselector.vue'], resolve)
        },
        "CustomerRelation": resolve => {
            require(['@/components/business/selector/customerrelationselector.vue'], resolve)
        },
        "ContractList": resolve => {
            require(['./contractlist.vue'], resolve)
        },
        "CustomerInfo": resolve => {
            require(['@/components/business/selector/customerselector.vue'], resolve)
        },
    }
}
</script>

<style>
.setstyle {
min-height: 200px;
padding: 0!important;
margin: 0;
overflow: auto;
cursor: default !important;
}
</style>
